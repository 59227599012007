import Image from "next/image";

export default function Home() {
  return (
    <div style={{ display: "grid", height: "100vh", padding: "1em" }}>
      <Image src="/clients-online.svg" width={620} height={290} alt="euforia" priority={true} />
    </div>
  );
}

Home.withoutLayout = true;
