import Image from "next/image";
import styles from "../styles/Footer.module.sass";

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div>
        <Image src="/clients-online.svg" width={155} height={32} alt="euforia" />
      </div>
    </footer>
  );
}
